<template>
  <div id="a-la-carte">
    <div class="filler"></div>
    <h1 style="font-weight: bold; color: white">À La Carte</h1>
    <div style="height: 20px"></div>
    <div v-if="loading" class="text-center">
      <b-spinner variant="secondary" label="Loading"></b-spinner>
    </div>
    <b-card-group columns>
      <b-card class="border-0 col-md-12"
              v-for="category in categories"
              :key="category.id"
              style="background-color: #121521"
              img-top
      >
        <b-card-header style="color: #CBA661; font-weight: bold; font-size: 20px">
          {{ category.name }}
        </b-card-header>
        <b-card-title
                v-for="menuItem in category['menu_items']"
                :key="menuItem.id"
                style="color: white; font-size: 16px; text-align: left; font-weight: bold"
        >
          {{ menuItem.item_name }}
          <b-row>
            <b-col class="col-9">
              <b-card-text style="font-style: italic; font-size: 14px; font-weight: lighter">
                {{ menuItem.remark_en }}
              </b-card-text>
            </b-col>
            <b-col>
              <b-card-text style="font-style: italic; font-size: 14px; font-weight: lighter; text-align: right">
                € {{ menuItem.price.toFixed(2) }}
              </b-card-text>
            </b-col>
          </b-row>
        </b-card-title>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
export default {
  data () {
    return {
      categories: [],
      loading: true
    }
  },
  beforeCreate () {
    this.axios
      .get('https://batavia-backend.herokuapp.com/api/category')
      .then(response => (this.categories = response.data))
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    getCategory(category) {
      return `<i class="material-icons">${category.icon}</i> ${category.name}`
    }
  },
  metaInfo: {
    title: 'À la carte',
    titleTemplate: '%s ← Batavia Amsterdam',
    meta: [
      {httpEquiv: 'Content-Type', content: 'text/html; charset=utf-8'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {name: 'description', content: 'À la carte'}
    ],
    link: [
      {rel: 'canonical', href: 'https://www.bataviamsterdam.com/a-la-carte'}
    ]
  }
}
</script>

<style scoped>
  @media only screen and (max-device-width: 568px) {
    #a-la-carte {
      margin-bottom: 2em;
    }
  }

  @media only screen and (min-device-width: 569px) and (max-device-width: 2000px) and (orientation: portrait) {
    #a-la-carte {
      margin-bottom: 17em;
    }
  }

  @media only screen and (min-device-width: 569px) and (max-device-width: 2000px) and (orientation: landscape) {
    #a-la-carte {
      margin-bottom: 1em;
    }
  }
</style>